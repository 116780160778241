<template>
  <div class="api-error full-height">
    <v-container class="full-height api-error-container" fluid>
      <v-row>
        <v-col cols="12" >
          <v-card
            class="mx-auto"
            max-width="500px"
          >
            <v-img
              src="../assets/images/error/500.png"
              height="100%"
            ></v-img>
        
            <v-card-title class="error-description">
              {{ $t(`error.error_500`) }}
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'Home',
  data () {
    return {
      
    }
  },
}
</script>